import React from 'react'
import { graphql, StaticQuery } from 'gatsby';
import DemoComponent from '../../../components/demo/DemoComponent';

function DemoTemplate({ cmsData }) {
  
  const contactData = cmsData?.demo?.edges[0]?.node?.frontmatter ?? {}

  return (
      <div>
          <DemoComponent cmsData={contactData}/>
      </div>
  )
}

export default function Demo() {
    return (
      <StaticQuery
        query={graphql`
          query {
            demo: allMarkdownRemark(filter: 
              {frontmatter: {templateKey: {eq: "en-demo"}}}) {
              edges {
                node {
                  frontmatter {
                    seoTitle
                    seoDescr
                    title
                    text
                    url
                  }
                }
              }
            },
          }
        `}
        render={(data) => <DemoTemplate cmsData={data} />}
      />
    );
  }
  